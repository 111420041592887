import { mapGetters, mapState } from 'vuex';
import { customMapActions } from '@/vue/stores/Store.js';

import { ACTIONS, GETTERS, nameSpace } from './AvailabilityStore.js';

export const {
  availabilityFilters,
  availabilityOldValues,
  previousAvailables,
  filtersAreCollapsed,
  openFiltersCollapsedPanel,
  isAvailabilityLoading,
  isAvailabilityFilterApplied,
  getAvailabilityResponse,
  getAvailabilityRange,
  getLocations,
  getOldCityValue,
  getOldBookingTypeValue,
  getOldStartValue,
  getOldEndValue,
  getBeforeAvailabilityActions,
  getAfterAvailabilityActions,
  getEmployeeProfileUuid,
  getEmployeeProfile,
  isSomeAvailabilityFilterApplied,
  getCollapsedFilters,
  getNotCollapsedFilters,
  getCollapsedFiltersApplied,
  toggleAvailabilityLoading,
  addBeforeAvailabilityAction,
  addAfterAvailabilityAction,
  setAvailabilityResponse,
  setOldCityValue,
  setOldProfileValue,
  setOldBookingTypeValue,
  setOldStartValue,
  setOldEndValue,
  resetAvailabilityStore,
  setEmptyMessageLocations,
  setWaitingGeolocation,
  setAvailabilityFilters,
  setAvailabilityFiltersDirectly,
  openAvailabilityFilter,
  resetSelectedFilters,
  setOldAvailabilityFilters,
  resetAllSelectedFilters,
  setFilterAreCollapsed,
  openMoreFiltersPanel,
  applyFilters,
  setEmployeeProfile,
  setNumFiltersAreCollapsed,
  getOldProfileValue,
} = {
  ...mapState(nameSpace, {
    availabilityFilters: state => state.filters,
    availabilityOldValues: state => state.oldValues,
    previousAvailables: state => state.previousAvailables,
    filtersAreCollapsed: state => state.filtersAreCollapsed,
    openFiltersCollapsedPanel: state => state.openFiltersCollapsedPanel,
  }),
  ...mapGetters(nameSpace, [
    GETTERS.isAvailabilityLoading,
    GETTERS.isAvailabilityFilterApplied,
    GETTERS.getAvailabilityResponse,
    GETTERS.getAvailabilityRange,
    GETTERS.getLocations,
    GETTERS.getOldCityValue,
    GETTERS.getOldProfileValue,
    GETTERS.getOldBookingTypeValue,
    GETTERS.getOldStartValue,
    GETTERS.getOldEndValue,
    GETTERS.getBeforeAvailabilityActions,
    GETTERS.getAfterAvailabilityActions,
    GETTERS.getEmployeeProfileUuid,
    GETTERS.getEmployeeProfile,
    GETTERS.isSomeAvailabilityFilterApplied,
    GETTERS.getCollapsedFilters,
    GETTERS.getNotCollapsedFilters,
    GETTERS.getCollapsedFiltersApplied,
  ]),
  ...customMapActions(nameSpace, [
    ACTIONS.toggleAvailabilityLoading,
    ACTIONS.addBeforeAvailabilityAction,
    ACTIONS.addAfterAvailabilityAction,
    ACTIONS.setAvailabilityResponse,
    ACTIONS.setOldCityValue,
    ACTIONS.setOldProfileValue,
    ACTIONS.setOldBookingTypeValue,
    ACTIONS.setOldStartValue,
    ACTIONS.setOldEndValue,
    ACTIONS.resetAvailabilityStore,
    ACTIONS.setEmptyMessageLocations,
    ACTIONS.setWaitingGeolocation,
    ACTIONS.setAvailabilityFilters,
    ACTIONS.setAvailabilityFiltersDirectly,
    ACTIONS.openAvailabilityFilter,
    ACTIONS.resetSelectedFilters,
    ACTIONS.setOldAvailabilityFilters,
    ACTIONS.resetAllSelectedFilters,
    ACTIONS.setFilterAreCollapsed,
    ACTIONS.openMoreFiltersPanel,
    ACTIONS.applyFilters,
    ACTIONS.setEmployeeProfile,
    ACTIONS.setNumFiltersAreCollapsed,
  ]),
};
