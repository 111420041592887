import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';
import {
  ACTIONS,
  NAMESPACE,
} from './VehicleAvailabilityModule';

export const {
  startAvailability,
  endAvailability,
  isError,
  isLoaded,
  isLoading,
  isVehicleAvailable,
  getVehicleAvailability,
  setVehicleAvailable,
} = {
  ...mapState(NAMESPACE, {
    isLoading: state => get(state, `STATUS.${STORE_REQUEST_STATUS.loading}`),
    isLoaded: state => get(state, `STATUS.${STORE_REQUEST_STATUS.loaded}`),
    isError: state => get(state, `STATUS.${STORE_REQUEST_STATUS.error}`),
    isVehicleAvailable: state => get(state, 'data.occupation.available', false),
    startAvailability: state => get(state, 'data.startAvailability', ''),
    endAvailability: state => get(state, 'data.endAvailability', ''),
  }),
  ...mapActions(NAMESPACE, {
    getVehicleAvailability: ACTIONS.getVehicleAvailability,
    setVehicleAvailable: ACTIONS.setVehicleAvailable,
  }),
};
